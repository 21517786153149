import React, { useState } from 'react';

import Home from './pages/Home';

import logo from './logo.svg';
import './App.less';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";

import { Layout, Menu, Row, Col } from 'antd';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

function App() {
    const [activeMenu, setActiveMenu] = useState('home');
    
    const handleMenuClick = (selectMenu) => {
        if (selectMenu) {
            setActiveMenu(selectMenu);
        }
        window.scrollTo(0, 0);
    };

    return (
        <Router>
          <div className="page">
            <Layout /*style={{ marginBottom: height }}*/>
              <Header>
                <div className="logo">
                  <Link to="/" onClick={() => handleMenuClick('/')}>
                    <img src="/images/logo.svg" />
                  </Link>
                </div>{/*
                <div className="menu">
                  <Menu
                    theme="light"
                    mode="horizontal"
                    defaultSelectedKeys={[activeMenu]}
                    selectedKeys={[activeMenu]}>
                    <Menu.Item key="entrar">
                      <Link to="/entrar" onClick={() => handleMenuClick("entrar")}>
                        Entrar
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="registo">
                      <Link to="/registar" onClick={() => handleMenuClick("Registar")}>
                        Registar
                      </Link>
                    </Menu.Item>
                  </Menu>
                </div>*/}
              </Header>
              <Content>
                <Switch>
                  <Route path="/" exact component={Home}/>
                </Switch>
              </Content>
              <Footer>
                <p>Consulte a <a href="https://doc.netuno.org" target="_blank">documentação</a>.</p>
                <p>Participe do <a href="https://forum.netuno.org" target="_blank">forum oficial</a>.</p>
                <p>Inicie na <a href="https://doc.netuno.org/docs/pt-PT/academy/overview/" target="_blank">academia</a>.</p>
                <p>Entre em contato conosco em <a href="https://www.sitana.pt">sitana.pt</a>.</p>
              </Footer>
            </Layout>
          </div>
        </Router>
    );
}

export default App;
