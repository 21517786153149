import React, { useState } from 'react';

import _service from '@netuno/service-client';

import { Switch, Select, Divider, Row, Col, Form, Input, InputNumber, Button, Spin, notification } from 'antd';

import { MinusCircleOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';

import './Perfil.less';

const layout = {
    rowGutter: { gutter: [50, 0] },
    spanCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 12 } },
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    labelAlign: "left"
};

const { Option } = Select;

export default ({ code, perfilData })=> {
    
    const [ submitted , setSubmitted ] = useState(false);
    const [isCompany, setIsCompany] = useState(true);
    const [peopleAgeValue, setPeopleAgeValue] = useState(0);
    const [ loading, setLoading ] = useState(false);

    const onFinish = values => {
        setLoading(true);
        const fail = () => {
            setLoading(false);
            notification.error({
                message: "Falhou...",
                description: "Houve uma falha ao tentar guardar os dados, reporte a situação e tente mais tarde. Desculpe o transtorno.",
                top: 100,
                duration: 15
            });
        };
        const success = () => {
            notification.success({
                message: "Inscrição Realizada",
                description: "Obrigado pela inscrição, brevemente receberá o mail com mais indicações.",
                top: 100,
                duration: 15
            });
        };
        
        _service({
            url: "perfil/update",
            method: 'POST',
            data: { code, ...values },
            success: (response) => {
                if (response.json) {
                    success();
                    setSubmitted(true);
                    setLoading(false);
                } else {
                    fail();
                }

            },
            fail: (e) => {
                console.log("Service perfil/update error:", e);
                fail();
            }
        });
        
    };
    
    const validateMessages = {
        required: '${label} é de preenchimento obrigatório.',
        types: {
            email: '${label} o email não é válido.',
            number: '${label} o número não é válido.',
        }
    };
    if (perfilData == null) {
        return <Spin/>;
    }
    if (submitted || perfilData.subscribed) {
        return (
            <div className="offline">
              <h1>{perfilData.people.name}, bem vindo a bordo!</h1>
              <p>O seu lugar foi reservado com sucesso.</p>
              <p>Aguarde as instruções que será enviada por e-mail brevemente.</p>
              <p>Obrigado e até logo.</p>
            </div>
        );
    }
    return (
        <div className="perfil">
          <Form {...layout} initialValues={perfilData} validateMessages={validateMessages} onFinish={onFinish}>
            <h2>Dados Pessoais</h2>
            <p>Complete e verifique os seus dados pessoais.</p>
            <divider/>
            <Row {...layout.rowGutter}>
              <Col {...layout.spanCol}>
                <Form.Item name={['people', 'name']} label="Nome" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...layout.spanCol}>
                <Form.Item name={['people', 'surname']} label="Sobrenome/Apelido" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row {...layout.rowGutter}>
              <Col {...layout.spanCol}>
                <Form.Item name={['people', 'email']} label="E-mail" rules={[{ required: true, type: 'email' }]}>
                  <Input />
                </Form.Item>
                <p>Vai receber por e-mail mais detalhes para formação, por favor certique que está correto.</p>
              </Col>
              <Col {...layout.spanCol}>
                <Form.Item name={['people', 'phone']} label="Telefone" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
                <Form.Item name={['people', 'git_hub']} label="Perfil no GitHub" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <h2>Empresa Atual</h2>
            <p>Caso trabalhe em alguma empresa por favor preencha os campos abaixo.</p>
            <Row {...layout.rowGutter}>
              <Col {...layout.spanCol}>
                <Form.Item name={['company', 'website']} label="Website" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...layout.spanCol}>
                <Form.Item name={['company', 'employees']} label="Total de Colaboradores" rules={[{ required: false }]}>
                  <InputNumber min={0}/>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <h2>Redes Sociais</h2>
            <Form.List name={["socialNetworks"]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map(field => (
                                <Row className="social-network-line" {...layout.rowGutter}>
                                    <Col sm={24} md={7}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'code']}
                                          fieldKey={[field.fieldKey, 'code']}
                                          label="Rede Social"
                                          rules={[{ required: true }]}>
                                          <Select>
                                            <Select.Option value="linkedin">LinkedIn</Select.Option>
                                            <Select.Option value="instagram">Instagram</Select.Option>
                                            <Select.Option value="facebook">Facebook</Select.Option>
                                            <Select.Option value="twitter">Twitter</Select.Option>
                                          </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={14}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'link']}
                                          fieldKey={[field.fieldKey, 'link']}
                                          label="Link"
                                          rules={[{ required: true }]}>
                                          <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={3}>
                                        <MinusCircleOutlined twoToneColor="#ff4d4f" onClick={() => { remove(field.name); }} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => { add(); }} block >
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Form.Item>
                        </div>
                    );
                }}
            </Form.List>
            <Divider />
            <h2>Principais Habilidades</h2>
            <Form.Item name={['people', 'experience']} label="Anos de Experiência Técnica" rules={[{ required: false }]}>
              <InputNumber min={0}/>
            </Form.Item>
            <Row {...layout.rowGutter}>
              {[
                  {code: 'nodejs', name: 'NodeJS' },
                  {code: 'sql', name: 'SQL' },
                  {code: 'linux', name: 'Linux' },
                  {code: 'reactjs', name: 'ReactJS' },
                  {code: 'frontend', name: 'Frontend' },
                  {code: 'backend', name: 'Backend' },
                  {code: 'code', name: 'Programação' },
                  {code: 'design', name: 'Design' },
                  {code: 'db', name: 'Base de Dados' },
                  {code: 'excel', name: 'Excel' },
                  {code: 'pdf', name: 'PDF' },
                  {code: 'js', name: 'JavaScript' },
                  {code: 'py', name: 'Python' },
                  {code: 'rb', name: 'Ruby' },
                  {code: 'kt', name: 'Kotlin' },
                  {code: 'java', name: 'Java' },
                  {code: 'php', name: 'PHP' },
                  {code: 'wp', name: 'WordPress' },
                  {code: 'laravel', name: 'Laravel' },
                  {code: 'mysql', name: 'MySQL' },
                  {code: 'psql', name: 'PostgreSQL' },
                  {code: 'ml', name: 'Machine Learning' },
                  {code: 'bigdata', name: 'Big Data' },
                  {code: 'rest', name: 'RestAPI' },
                  {code: 'svg', name: 'SVG/AI' },
                  {code: 'ubuntu', name: 'Ubuntu' },
                  {code: 'cs', name: '.Net/C#' },
                  {code: 'mq', name: 'Message Queue' }
              ].map(field => (
                  <Col {... { xs: { span: 12 }, sm: { span: 8 }, md: { span: 8 }, lg: { span: 6 } }}>
                    <Form.Item
                      {...field}
                      name={["skills", field.code]}
                      fieldKey={[field.fieldKey, 'code']}
                      label={field.name}>
                      <Switch />
                    </Form.Item>
                  </Col>
              ))}
            </Row>
            { !submitted ?
              <>
                <Divider/>
                <Row {...layout.rowGutter}>
                  <Col span={24}>
                    <Form.Item wrapperCol={24}>
                      <Button htmlType="submit" type="primary" block loading={loading}>Enviar</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>: null
            }
          </Form>
        </div>
    );
};
