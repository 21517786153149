import React, { useState, useEffect } from 'react';

import { Spin, notification } from 'antd';

import _service from '@netuno/service-client';

import Perfil from '../containers/Perfil';

import './Home.less';

let { hash } = window.location;

export default ()=> {
    const [loading, setLoading] = useState(true);
    const [invalid, setInvalid] = useState(false);
    const [offline, setOffline] = useState(false);
    const [perfilData, setPerfilData] = useState(null);
    
    useEffect(() => {
        if (hash == "") {
            setInvalid(true);
            return;
        }
        if (hash.length < 36) {
            setInvalid(true);
            return;
        }
        hash = hash.substring(1);
        const fail = () => {
            setLoading(false);
            setOffline(true);
        };

        _service({
            url: "invite/load",
            method: 'POST',
            data: { code: hash },
            success: (response) => {
                if (response.json) {
                    setLoading(false);
                    if (response.json.error) {
                        setInvalid(true);
                    } else {
                        setPerfilData(response.json);
                    }
                } else {
                    fail();
                }
            },
            fail: (e) => {
                console.log("Service invite/load error:", e);
                fail();
            }
        });
    }, []);

    if (invalid) {
        return (
            <div className="invalid">
              <h1>Este link é inválido.</h1>
              <p>Precisa ter um convite de inscrição válido.</p>
              <p>Entre em contato conosco em <a href="https://www.sitana.pt">sitana.pt</a>.</p>
            </div>
        );
    }
    
    if (loading) {
        return (
            <div className="loading">
              <Spin/> &nbsp; a carregar...
            </div>
        );
    }

    if (offline) {
        return (
            <div className="offline">
              <h1>Há problemas na inscrição neste momento.</h1>
              <p>Entre em contato com o seu formador e volte a tentar mais tarde.</p>
              <p>Desculpe o transtorno.</p>
            </div>
        );
    }

    return (
        <div>
          <div className="cover" style={{backgroundImage: 'url(/images/cover-maranhao.jpg)'}}>
            <div className="cover__content">
              <p>Olá <b>{perfilData ? perfilData.people.name : ''}</b>.</p>
              <p>Seja bem vindo na academia do Netuno Maranhão.</p>
              <p>O seu lugar está quase reservado.</p>
              <p>A formação começa no dia 22 de Março e termina no dia 12 de Maio.</p>
              <p>Todas as Segundas, Terças e Quartas das 20h até às 21h.</p>
              <p>São 24h de formação gratuita exclusiva para residentes do Maranhão.</p>
              <p>Qualquer dúvida entre em contato com o seu formador.</p>
            </div>
          </div>
          <Perfil {...{ code: hash, perfilData}} />
        </div>
    );
};
